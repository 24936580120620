<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <div slot="header">
        Order ID <strong>#{{ this.order_id }}</strong>
      </div>
      <b-card-body>
          <div class="loading" v-if="isLoading == true">
              <div class="sk-three-bounce">
                  <div class="sk-child sk-bounce1"></div>
                  <div class="sk-child sk-bounce2"></div>
                  <div class="sk-child sk-bounce3"></div>
              </div>
          </div>
        <b-row class="mb-4">
          <b-col sm="4">
            <h6>Order Date</h6>
            <div class="mb-3"><strong>{{ this.order_date }}</strong></div>
            <h6>Metode Pembayaran</h6>
            <div class="mb-3"><strong>{{ this.payment_method }}</strong></div>
            <h6 v-if="this.payment_method != 'COD'">Jasa Pengiriman</h6>
            <div class="mb-3" v-if="this.payment_method != 'COD'"><strong>{{ this.shipping_provider }}</strong></div>
            <h6>Nomor Resi</h6>
            <div class="mb-3"><strong>{{ this.tracking_no }}</strong></div>
            <h6>Status Barang</h6>
            <div class="mb-3"><strong>{{ this.status}}</strong></div>
          </b-col>
          <b-col sm="4">
            <h6>Email Pemesan</h6>
            <div class="mb-3"><strong>{{ this.email }}</strong></div>
            <h6>Nama Penerima</h6>
            <div class="mb-3"><strong>{{ this.name }}</strong></div>
            <h6>Nomor Penerima</h6>
            <div class="mb-3"><strong>{{ this.phone == null ? '-' : this.phone }}</strong></div>
            <h6>Status Pembayaran</h6>
            <div class="mb-3"><strong>{{ this.payment_status }}</strong></div>
          </b-col>
          <b-col sm="4">
            <h6>Biaya Kirim</h6>
            <div class="mb-3"><strong>{{ this.shipping_cost | currency }}</strong></div>
            <h6 v-if="this.method == 'cetak' ">Harga Cetak</h6>
            <h6 v-else-if="this.method == 'direct' ">Harga Koin</h6>
            <div class="mb-3"><strong>{{ this.total | currency }}</strong></div>
            <h6>Catatan</h6>
            <div class="mb-3"><strong>{{ this.note }}</strong></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h6>Alamat Penerima</h6>
            <div class="mb-3"><strong>{{ this.address }}</strong></div>
          </b-col>
          <b-col>
            <h6>Provinsi</h6>
            <div class="mb-3"><strong>{{ this.province === null ? '-' : this.province }}</strong></div>
          </b-col>
          <b-col>
            <h6>Kota</h6>
            <div class="mb-3"><strong>{{ this.city === null ? '-' : this.city }}</strong></div>
          </b-col>
          <b-col>
            <h6>Kode Pos</h6>
            <div class="mb-3"><strong>{{ this.postal_code === null ? '-' : this.postal_code }}</strong></div>
          </b-col>
        </b-row>
        <div class="table-responsive-sm">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Product</th>
                <th class="center">Quantity</th>
                <th class="right">Price</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(items, index) in item" :key="index">
                <td class="left">
                    <strong>{{ items.product_name }}</strong> <br>
                    <span>{{ items.gram_per_unit }} Gram</span>
                </td>
                <td class="center">{{ items.qty }} Pcs</td>
                <td class="right">{{ items.price | currency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-row>
          <b-col lg="4" sm="5">
            <h6>Note dari Treasury :</h6>
            <strong>{{ this.note_to_customer != null ? this.note_to_customer : '-'}}</strong><br><br>
            <button class="mb-3 btn btn-info" v-if="this.invoice_number == null && this.payment_method != null && this.payment_method != 'COD'" @click="generate">Generate Invoice</button>&nbsp;
            <button class="mb-3 btn btn-danger" v-if="this.invoice_number != null && this.payment_status == 'Menunggu Pembayaran'" @click="cancel">Cancel Invoice</button>&nbsp;
            <button class="mb-3 btn btn-success" v-if="this.tracking_no == null && this.payment_status == 'Sudah Dibayar' && this.payment_method != 'COD'" @click="sendShipment">Send Shipment Data</button>&nbsp;
            <button class="mb-3 btn btn-success" v-if="this.tracking_no != null && this.payment_status == 'Sudah Dibayar' && this.pickup_request_no == null && this.pin == null && this.shipping_provider != 'paxel'" @click="sendPickup">Send Pickup Data</button>
          </b-col>
          <b-col lg="4" sm="5" class="ml-auto">
            <table class="table table-clear">
              <tbody>
                <tr>
                  <td class="left"><strong>Shipping Fee</strong></td>
                  <td class="right">{{ this.shipping_cost | currency }}</td>
                </tr>
                <tr v-if="this.tax != null">
                  <td class="left"><strong>Pajak (10 %)</strong></td>
                  <td class="right">{{ this.tax | currency }}</td>
                </tr>
                <tr>
                  <td class="left"><strong>Total</strong></td>
                  <td class="right"><strong>{{ this.total + this.shipping_cost | currency }}</strong></td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
export default {
    name: 'invoice',
    data() {
        return {
            note: '',
            total: '',
            price: "",
            tax: "",
            payment_method: '',
            tracking_no: '',
            status: '',
            quantity: '',
            product: '',
            payment_status: "",
            address: '',
            order_id: '',
            order_date: '',
            shipping_cost: '',
            shipping_provider: '',
            receiver_name: '',
            phone: '',
            name: '',
            email: '',
            method: "",
            note_to_customer: "",
            weight: "",
            invoice_number: '',
            pin: '',
            pickup_request_no: '',
            detail: '',
            province: '',
            city: '',
            postal_code: '',
            coin: [],
            item:[],
            errors: {
              code: '',
              message: '',
              status: ''
            },
            isLoading: false
        }
    },
    created() {

        this.$http.get(`detail-order/` + this.$route.params.id)
        .then((result) => {
            this.order_id = result.data.order.order_id;
            this.order_date = result.data.order.created_at;
            this.payment_method = result.data.payment;
            this.shipping_provider = result.data.order.shipping_provider;
            this.tracking_no = result.data.order.tracking_no;
            this.email = result.data.order.user.email;
            this.address = result.data.order.shipping_address;
            this.province = result.data.order.province;
            this.city = result.data.order.city;
            this.postal_code = result.data.order.postal_code;
            this.name = result.data.order.receiver_name;
            this.phone = result.data.order.phone;
            this.status = result.data.order.status;
            this.payment_status = result.data.order.payment_status;
            this.shipping_cost = result.data.order.shipping_fee;
            this.note = result.data.order.customer_note;
            this.note_to_customer = result.data.order.note_to_customer;
            this.item = result.data.order.items;
            this.price = result.data.order.items[0].price;
            this.total = result.data.order.item_total;
            this.tax = result.data.order.tax;
            this.coin = result.data.product;
            this.method = result.data.order.method;
            this.invoice_number = result.data.order.invoice_number;
            this.pin = result.data.order.pin;
            this.pickup_request_no = result.data.order.pickup_request_no;
            this.detail = result.data.order.id;
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
          }
        })
    },
    methods: {
      generate() {
        this.isLoading = true
        this.$http.get(`dinar-orders/` + this.detail +`/generate`)
        .then(() => {
          this.isLoading = false
          this.$swal.fire(
            'Success!',
            'Invoice was successfully generated.',
            'success'
          ).then(() =>{
            location.reload()
          })
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
            if (this.errors.status == 422) {
              this.$swal.fire(
                'Failed!',
                'Invoice failed to generate.',
                'error'
              ).then(() =>{
                location.reload()
              })
            }
          }
        })
      },
      cancel() {
        this.isLoading = true
        this.$http.get(`dinar-orders/` + this.invoice_number +`/cancel`)
        .then(() => {
          this.isLoading = false
          this.$swal.fire(
            'Success!',
            'Invoice was successfully canceled.',
            'success'
          ).then(() =>{
            location.reload()
          })
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
            if (this.errors.status == 422) {
              this.$swal.fire(
                'Failed!',
                'Invoice failed to cancel.',
                'error'
              ).then(() =>{
                location.reload()
              })
            }
          }
        })
      },
      sendShipment(){
        this.isLoading = true
        this.$http.get(`dinar-orders/` + this.detail +`/send`)
        .then((result) => {
          this.isLoading = false
          this.$swal.fire(
            'Success!',
            result.data.meta.message,
            'success'
          ).then(() =>{
            location.reload()
          })
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
            if (this.errors.status == 422) {
              this.$swal.fire(
                'Failed!',
                error.response.data.meta.message,
                'error'
              ).then(() =>{
                location.reload()
              })
            }
          }
        })
      },
      sendPickup() {
        this.isLoading = true
        this.$http.get(`dinar-orders/` + this.detail +`/pickup`)
        .then(() => {
          this.isLoading = false
          this.$swal.fire(
            'Success!',
            'Pickup successfully sent.',
            'success'
          ).then(() =>{
            location.reload()
          })
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
            if (this.errors.status == 422) {
              this.$swal.fire(
                'Failed!',
                'Pickup failed to send',
                'error'
              ).then(() =>{
                location.reload()
              })
            }
          }
        })
      }
    },
}
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
